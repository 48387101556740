import React from 'react';

import classes from './InfosSlide.module.css';
import images from '../../../../assets/images/cities';


const infosSlide = (props) => (
    
    <React.Fragment>
        <div className={classes.TitleCity+" "+classes.Border}>{props.theName}</div>
        
            <img className = {classes.Border+" "+classes.ImageCity} src={`${images[props.theImg]}`} alt={props.theName}/> 
        
    </React.Fragment>
) 

export default infosSlide;