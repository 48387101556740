
import React from 'react';
import {useSpring, animated} from 'react-spring'


import Carousel from '../../../component/Portfolio/Carousel/Carousel';
import classes from './Portfolio.module.css';
import BackImg from '../../../assets/images/back1.jpg'
 
const Portfolio = (props) => {

    const fadInAnim = useSpring({
        from: {transform:"translateY(-100%)"},
        to: async (next, cancel) => {
          await next({transform:"translateY(0)"})
        }         
    });

    
    return (
        <div className= {classes.Portfolio} style={{
            background : `#161415 url(${BackImg}) no-repeat top center`,
            backgroundSize: 'cover',
            height : 'calc(100vh)'
            
        }}>
            <animated.div style={fadInAnim} className = {classes.Container} >
                <Carousel />

            </animated.div>

        </div>
    );
    
};
 
export default Portfolio;