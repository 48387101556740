//import axios from 'axios';
import * as actionTypes from './actionTypes';
//import * as config from '../../utilities/conf';
import fetchPtf from '../../assets/data/portfolio.json';


export const fetchPortfolioSuccess = (portfolio) => {
    return {
        type : actionTypes.FETCH_PORTFOLIO_SUCCESS,
        portfolio : portfolio
    } 
};

export const fetchPortfolioFail = (error) => {
    return {
        type : actionTypes.FETCH_PORTFOLIO_FAIL,
        error:error
    }
};

export const fetchPortfolioStart = () => {
    return {
        type : actionTypes.FETCH_PORTFOLIO_START
        
    }
};


export const fetchPortfolio = () => {
   
    return dispatch => {
        
/*         dispatch(fetchPortfolioStart());
        axios.get(config.URL_PORTFOLIO)
        .then(response => {
            const fetchPortfolio = [];
            for (let key in response.data){
                fetchPortfolio.push({
                    ...response.data[key]
                    
                
                })
            }
            dispatch(fetchPortfolioSuccess(fetchPortfolio));

        })
        .catch (err=> {
            dispatch(fetchPortfolioFail(err));

        }); */

        dispatch(fetchPortfolioStart());
        const fetchPortfolio = [];
        for (let key in fetchPtf){
            fetchPortfolio.push({
                ...fetchPtf[key]
                
            
            })
        }
        dispatch(fetchPortfolioSuccess(fetchPortfolio));

    };
};
