import React, { Component } from 'react';
import SliderItem from '../../component/Sections/Resume/SliderItem/SliderItem';
import {connect} from 'react-redux';

import * as actions from '../../store/actions/index';
import classes from './Slider.module.css';

class Slider extends Component{
 

    activeItemHandler = (id) => {
/*         const updatedItems = this.props.cities.map(itm => {
              itm.isActive = (itm.id === id) ? true : false;
              return itm;
        });
        this.setState({itemsInfo : updatedItems}) */
        //
       
        this.props.onChangeCity(id);
    }
        
    render () {
        let items = this.props.cities.map(itm => (
            <li key={itm.id} onClick={()=> this.activeItemHandler(itm.id)}>
                <div 
                    className={classes.Cercle+" "+(itm.isActive ? classes.active :'')}>
                        
                    </div>
                    <SliderItem isActive={itm.isActive}  dateItm = {itm.date}/>
            </li>
        ))
        return (
            <ul className={classes.Slider}>
                {items}
            </ul>
        
        )
    }
};

const mapStateToProps = state => {
    return {
       cities : state.resume.cities,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onChangeCity : (id) => dispatch(actions.changeCity(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Slider); 