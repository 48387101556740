import React, { Component,Suspense  } from 'react';
import { Route,Switch,Redirect } from 'react-router-dom';

//import classes from './Layout.module.css'
import Toolbar from '../../component/Navigation/toolbar/Toolbar';
import Contacts from '../../Containers/sections/Contacts/Contacts';
import Resume from '../../Containers/sections/Resume/Resume';

import Portfolio from '../../Containers/sections/Portfolio/Portfolio';

// import Skills from '../../Containers/sections/Skills/Skills';
// import Projects from '../../Containers/sections/Projects/Projects';

//import classes from './Layout.module.css';

const Skills = React.lazy(() => import ('../../Containers/sections/Skills/Skills')) 
const Projects = React.lazy(() => import ('../../Containers/sections/Projects/Projects'))


class Layout extends Component {

    

    render() {

        return (
            <React.Fragment>
                    <Toolbar  />
                    <Switch>
                    <Route path='/home'  component={Resume}/>
                 {/*     <Route path='/skills'  component={Skills}/>  */}
                     <Route path='/skills'  render={() => (
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <Skills />
                                                </Suspense> 
                        )}/>

                 {/*  <Route path='/projects'  component={Projects}/>  */}
                   <Route path='/projects'  render={() => (
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <Projects />
                                                </Suspense> 
                        )}/>                  
                    <Route path='/portfolio'  component={Portfolio}/>
                    <Route path='/contacts'  component={Contacts}/>
                    <Route path="/" exact  component={Resume}/>
                     <Redirect to="/"/> 

                    </Switch>
                
                
            </React.Fragment>
        )
    }
};



export default Layout;