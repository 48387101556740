import React from 'react';
import classes from './toolbar.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';


const toolbar = (props) => (
    <nav className={classes.Toolbar}>

        <NavigationItems />
    </nav>
);

export default toolbar;
