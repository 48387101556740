export const CHANGE_CITY_EVENT = 'CHANGE_CITY_EVENT';



export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';

export const FETCH_PORTFOLIO_START = 'FETCH_PROJECTS_START';
export const FETCH_PORTFOLIO_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PORTFOLIO_FAIL = 'FETCH_PROJECTS_FAIL';
