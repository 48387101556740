import React, { useEffect } from 'react';
import {connect} from 'react-redux';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import classes from './Carousel.module.css';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../component/UI/Spinner/Spinner';
import images from '../../../assets/images/portfolio';


const MyCarousel = (props) => {
    let carousel = <Spinner />;
    let portfolio = [];

    const  {onFetchPortfolio} = props;

    useEffect(() => {
        onFetchPortfolio();         
    },[onFetchPortfolio])


    if (!props.loadingPf && props.portfolio){
         portfolio = props.portfolio.map( (pf,i) =>(
            <div key={i}>
                <img src={images[pf.image]} alt=""/>
                <p className={"legend "+classes.Legend}>{pf.detail}</p>
            </div>
        ));
        carousel = (
            <Carousel  showStatus={false} showArrows={false} transitionTime={800} className={classes.PresentationMode}>
                {portfolio}
            </Carousel>
        ) 
    }

    return (
/*         <Carousel width="100%" showArrows={false} transitionTime={800} className={classes.PresentationMode}>     
            <div >
                <img src="portfolio/2.png" alt=""/>
                <p className="legend">Legend 1</p>
            </div>
            <div >
                <img src="portfolio/app3.png" alt=""/>
                <p className="legend">Legend 2</p>
            </div>
        </Carousel> */
        <React.Fragment>
            {carousel}

        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        portfolio : state.prtfo.portfolio,
        loadingPf : state.prtfo.loadingPf
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPortfolio : () => dispatch(actions.fetchPortfolio())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MyCarousel); 