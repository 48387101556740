import React from 'react';
import classes from './NavigationItems.module.css';
import {NavLink} from 'react-router-dom';

const navigationItems =(props) => (
        <ul className={classes.NavigationItems}>


            <li className={classes.NavigationItem}> 
                <NavLink 
                    to="/"
                    exact
                    activeClassName={classes.active}
                    >Home
                </NavLink>
            </li>
            <li className={classes.NavigationItem}> 
                <NavLink 
                    to="/skills"
                    activeClassName={classes.active}
                    >Skills
                </NavLink>
                </li>
            <li className={classes.NavigationItem}>           

                <NavLink 
                    to="/projects"
                    exact
                    activeClassName={classes.active}
                    >Projects
                </NavLink>
                </li>
            <li className={classes.NavigationItem}>             
                <NavLink 
                    to="/portfolio"
                    exact
                    activeClassName={classes.active}
                    >Portfolio
                </NavLink>
                </li>
            <li className={classes.NavigationItem}>             
                <NavLink 
                    to="/contacts"
                    exact
                    activeClassName={classes.active}
                    >Contacts
                </NavLink>
            </li>
        </ul>
    
);

export default navigationItems;
