import * as actionTypes from '../actions/actionTypes';

const initialeState = {
    cities :[



        {
            id :
            4, name: "Lyon",
            date :"JUNE 2019",   
            coordinates: [4.8356,45.764043], 
            imgCity:'lyon',
            isActive:true,
            content : 
                "<strong>ESRI<br/> OCT 2019 => FEB 2020:</strong><br> Consultant freelancer, GIS Apps<br><br>"+
                "<strong>Business Document <br/> MAY 2019 => OCT 2019:</strong><br> Consultant freelancer, Software Package",
            contentImg : false,
            imgName :''
        },
        {
            id :
            3,name:
            "Paris",
            date :"SEPT 2012",      
            coordinates: [2.3522,48.8566],
            imgCity : 'paris',
            isActive:false,
            content : 
            "<strong>Business Document <br/> JUL 2018 => MAY 2019:</strong><br> Consultant, Software Package<br><br>"+
            "<strong>GFI INFORMATIQUE <br/> JAN 2015 => JUL 2018:</strong><br> Design and deveopment Engineer, GIS Apps <br><br>"+
            "<strong>ECOLE NATIONALE DES SCIENCES GEOGRAPHIQUES <br/> 2012 => 2014:</strong><br> Master degree, Geomatics Engineer <br>",
            contentImg : false,
            imgName :''

        },
        {
            id : 2,
            name: "Casablanca",
            date :"SEPT 2010",  
            coordinates: [-7.58984,33.57311],
            imgCity : 'casa',
            isActive:false,
            content : "<strong>ECOLE HASSANIA DES TRAVAUX PUBLICS <br/> 2010 => 2012:</strong><br> Master degree, GIS Engineer<br>",
            contentImg : true,
            imgName :'gis'
        },

        {
            id : 1,
            name: "Agadir",
            date :"SEPT 2008",
            coordinates: [-9.5981,30.4277],
            imgCity : 'agadir',
            isActive:false,
            content : "<strong>REDA SLAOUI School <br/> Morocco 2008 => 2010:</strong><br> Preparatory classes, Maths & Physics<br><br>",
            contentImg : true,
            imgName :'fourier'
        }
  ]
};

const changeCity = (state, action) => {
    const cities = state.cities.map(itm => {
        itm.isActive = (itm.id === action.cityId) ? true : false;
        return itm;
  });
  
    return {...state, 
        cities: cities
    };
}

const reducer = (state = initialeState,action) => {

    switch(action.type){
        case actionTypes.CHANGE_CITY_EVENT : return changeCity(state,action);

        default : return state
    }
};

export default reducer;