//import axios from 'axios';
import * as actionTypes from './actionTypes';
//import * as config from '../../utilities/conf';
import fetchPrjs from '../../assets/data/projects.json';



export const fetchProjectsSuccess = (projects) => {
    return {
        type : actionTypes.FETCH_PROJECTS_SUCCESS,
        projects : projects
    }
};

export const fetchProjectsFail = (error) => {
    return {
        type : actionTypes.FETCH_PROJECTS_FAIL,
        error:error
    }
};

export const fetchProjectsStart = () => {
    return {
        type : actionTypes.FETCH_PROJECTS_START
        
    }
};


export const fetchProjects = () => {
    
    return dispatch => {
/*         console.log(initProjects);
        dispatch(fetchProjectsStart());
        axios.get(config.URL_PROJECTS)
        .then(response => {
            const fetchProjects = [];
            for (let key in response.data){
                fetchProjects.push({
                    ...response.data[key]
                    
                
                })
            }
            dispatch(fetchProjectsSuccess(fetchProjects));

        })
        .catch (err=> {
            dispatch(fetchProjectsFail(err));

        }); */
        dispatch(fetchProjectsStart());
        const fetchProjects = [];
        for (let key in fetchPrjs){
            fetchProjects.push({
                ...fetchPrjs[key]           
            
            })
        }
        dispatch(fetchProjectsSuccess(fetchProjects));

    };
};
