import * as actionTypes from '../actions/actionTypes';

const initialeState = {
    portfolio : [],
    loadingPf : false
}

const fetchPortfolioStart = (state, action) => {
    return {
        ...state, 
        loadingPf : true
    };
}

const fetchPortfolioSuccess = (state, action) => {
    return {
        ...state,
        portfolio: action.portfolio,
        loadingPf: false
    };
}

const fetchPortfolioFail = (state, action) => {

    return {
        ...state,
        loadingPf:false

    };
}

const reducer = (state = initialeState,action) => {

    switch(action.type){
        case actionTypes.FETCH_PORTFOLIO_START : return fetchPortfolioStart(state,action);
        case actionTypes.FETCH_PORTFOLIO_SUCCESS : return fetchPortfolioSuccess(state,action);
        case actionTypes.FETCH_PORTFOLIO_FAIL : return fetchPortfolioFail(state,action);

        default : return state
    }
};

export default reducer;