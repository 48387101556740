
import * as actionTypes from './actionTypes';



export const changeCity = (cityId) => {

    return {
        type : actionTypes.CHANGE_CITY_EVENT,
        cityId : cityId
    }
};
