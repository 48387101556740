import React, { Component } from 'react';

import Contact from '../../../component/Sections/Contacts/Contact/Contact';
import classes from './Contacts.module.css';
import BackImg from '../../../assets/images/back1.jpg';

class Contacts extends Component {

 

    render () {

        return (
            <div className ={classes.Contacts} style={{
                'background' : `#161415 url(${BackImg}) no-repeat top center`,
                'backgroundSize': 'cover',
            }}>
              <Contact />  
            </div>
        );
    }
};

export default Contacts;