import React from 'react';
import classes from './SliderItem.module.css';


const sliderItem = (props) => {

    const activeSliderItem = props.isActive ? classes.ActiveSliderItem : "";
    return (
        <div className= {classes.SliderItem+" "+activeSliderItem}>
                {props.dateItm}
        </div>
    );
};

export default sliderItem;