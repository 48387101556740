const images = {
    bdoc1: require('./bdoc1.png'),
    bdoc2: require('./bdoc2.png'),
    burg: require('./burg.png'),
    ict: require('./ict.png'),
    ict2: require('./ict2.png'),
    keolis1: require('./keolis1.PNG'),
    keolis2: require('./keolis2.PNG'),
    mairie: require('./mairie.png'),
    pop1: require('./pop1.png'),
    pop2: require('./pop2.png'),
    pop3: require('./pop3.png'),

};
    
export default images;