import React from 'react';

import classes from './Defs.module.css';





const defs = (props) => (
    <React.Fragment>
        
    {/*     <div className={classes.Iam}  ><span>Hamza</span><br /><span>BOUNOUIDAR</span></div>
        <div className={classes.Nutshell}>In a nutshell...</div> */}

        <div className={classes.Nutshell}>In a nutshell...</div>
        <div className={classes.Iam}>
            <span className={classes.Return}>return </span ><span style={{fontSize:"1vw"}}>{"{"}</span><br/>
                <span className={classes.Param} >who:</span> <span className={classes.Value} style={{fontSize:"2vw"}}>"HAMZA BOUNOUIDAR"</span><br/>
                <span className={classes.Param} >as:</span> <span className={classes.Value} style={{fontSize:"1.8vw"}} >"Developer"</span><br/>
                <span className={classes.Param} >optional:</span> <span className={classes.Value} style={{fontSize:"1.8vw"}}>"GIS"</span><br/>
            <span style={{fontSize:"1vw"}}>{'}'}</span>
        </div>
    </React.Fragment>
)

/*   return {
    who: "Hamza",
    as : "developer"
}   */

export default defs;

